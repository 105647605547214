import React, { useState } from "react";
import PropTypes from "prop-types";
import { graphql, navigate } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const ContactPageTemplate = ({ title, subtitle, content, contentComponent }) => {
    const [values, setValues] = useState({});

    const PageContent = contentComponent || Content;

    const handleChange = e => {
        if(e.target.type == "file"){
            setValues({ ...values, [e.target.name]: e.target.files[0] });
        }else{
            setValues({ ...values, [e.target.name]: e.target.value });
        }
    };

    const handleSubmit = e => {
        e.preventDefault();
        const form = e.target;

        const data = new FormData();

        Object.entries(values).forEach(([key, value]) => {
            data.append(key, value);
        })
        
            fetch("/.netlify/functions/enquiry", {
                method: "POST",
                body: data
            })
                .then(res => res.json())
                .then(res => {
                    navigate(form.getAttribute("action"));
                })
                .catch(console.log);
        
    };

    return (
        <section className="section">
            <div className="container">
                <div className="content">
                    <div class="columns">
                        <div class="column">
                            <h1 className="title is-size-3 has-text-weight-bold is-bold-light">
                                {title}
                            </h1>
                            <h4>{subtitle}</h4>
                            <form
                                name="contact"
                                method="post"
                                action="/contact/thanks/"
                                onSubmit={handleSubmit}
                            >
                                <div hidden>
                                    <label>
                                        Don’t fill this out:{" "}
                                        <input
                                            name="bot-field"
                                            onChange={handleChange}
                                        />
                                    </label>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"name"}>
                                        Your Name
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"text"}
                                            name={"name"}
                                            onChange={handleChange}
                                            id={"name"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"number"}>
                                        Contact Number
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"telephone"}
                                            name={"number"}
                                            onChange={handleChange}
                                            id={"number"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"email"}>
                                        Email
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"email"}
                                            name={"email"}
                                            onChange={handleChange}
                                            id={"email"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"design"}>
                                        Design
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"design"}
                                            onChange={handleChange}
                                            id={"design"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label
                                        className="label"
                                        htmlFor={"placement"}
                                    >
                                        Placement
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"placement"}
                                            onChange={handleChange}
                                            id={"placement"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"colour"}>
                                        Colour
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"colour"}
                                            onChange={handleChange}
                                            id={"colour"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"size"}>
                                        Size
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"size"}
                                            onChange={handleChange}
                                            id={"size"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label
                                        className="label"
                                        htmlFor={"cover-up"}
                                    >
                                        Cover-up
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"cover-up"}
                                            onChange={handleChange}
                                            id={"cover-up"}
                                            required={false}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label className="label" htmlFor={"preferred-artist"}>
                                        Preferred Artist
                                    </label>
                                    <div className="control">
                                        <input
                                            className="input"
                                            type={"text"}
                                            name={"preferred-artist"}
                                            onChange={handleChange}
                                            id={"preferred-artist"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <label
                                        className="label"
                                        htmlFor={"preferred-days"}
                                    >
                                        Preferred Days/Dates
                                    </label>
                                    <div className="control">
                                        <textarea
                                            className="textarea"
                                            name={"preferred-days"}
                                            onChange={handleChange}
                                            id={"preferred-days"}
                                            required={true}
                                        />
                                    </div>
                                </div>
                                <div className="field">
                                    <button
                                        className="button is-link"
                                        type="submit"
                                    >
                                        Send
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div class="column">
                            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                                Find Us
                            </h2>
                            <div className="map-container">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d589.80869651438!2d-2.7302977118342304!3d53.749702298747984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b6d9d7b95a5c1%3A0xd25672ebac8f2a42!2s27%20Liverpool%20Rd%2C%20Penwortham%2C%20Preston%20PR1%209XD%2C%20UK!5e0!3m2!1sen!2sus!4v1572302865848!5m2!1sen!2sus"
                                    width="400"
                                    height="400"
                                    frameborder="0"
                                    style={{
                                        border: 0,
                                        height: 400,
                                        width: "100%"
                                    }}
                                    allowfullscreen=""
                                ></iframe>
                            </div>
                            <PageContent
                                className="content"
                                content={content}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

ContactPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func
};

const ContactPage = ({ data }) => {
    const { markdownRemark: post } = data;

    return (
        <Layout>
            <ContactPageTemplate
                contentComponent={HTMLContent}
                title={post.frontmatter.title}
                subtitle={post.frontmatter.subtitle}
                content={post.html}
            />
        </Layout>
    );
};

ContactPage.propTypes = {
    data: PropTypes.object.isRequired
};

export default ContactPage;

export const contactPageQuery = graphql`
    query ContactPage($id: String!) {
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                title
                subtitle
            }
        }
    }
`;
